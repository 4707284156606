// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

. {
  display: block;
  width: 100%;
  padding: $-padding-y $-indicator-padding $-padding-y $-padding-x;
  font-family: $-font-family;
  @include font-size($-font-size);
  font-weight: $-font-weight;
  line-height: $-line-height;
  color: $-color;
  background-color: $-bg;
  background-image: escape-svg($-indicator);
  background-repeat: no-repeat;
  background-position: $-bg-position;
  background-size: $-bg-size;
  border: $-border-width solid $-border-color;
  @include border-radius($-border-radius, 0);
  @include box-shadow($-box-shadow);
  appearance: none;

  &:focus {
    border-color: $-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($-box-shadow, $-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $-focus-box-shadow;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $-disabled-color;
    background-color: $-disabled-bg;
    border-color: $-disabled-border-color;
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $-color;
  }
}

.-sm {
  padding-top: $-padding-y-sm;
  padding-bottom: $-padding-y-sm;
  padding-left: $-padding-x-sm;
  @include font-size($-font-size-sm);
}

.-lg {
  padding-top: $-padding-y-lg;
  padding-bottom: $-padding-y-lg;
  padding-left: $-padding-x-lg;
  @include font-size($-font-size-lg);
}
